import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import AppLogo from '../../assets/images/logo-app.png';

function SEO({ lang = 'en', meta = [], data = {} }) {
  const {
    author,
    ogImage,
    seo: {
      focus_synonyms: keywords,
      meta_description: metaDescription,
      seo_title: title,
    } = {},
  } = data || {};

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={'BFF'}
      titleTemplate={title ? `${title} | %s` : 'BFF'}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: keywords?.join(','),
        },
        {
          property: `og:title`,
          content: title || 'BFF',
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: keywords?.join(','),
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `facebook-domain-verification`,
          content: `g52cc7615bnmc0qo7zsoxqnx51pxts`,
        },
      ]
        .concat(
          ogImage
            ? [
                {
                  property: 'og:image',
                  content: ogImage?.asset?.fluid?.src,
                },
                {
                  name: 'twitter:card',
                  content: 'summary_large_image',
                },
              ]
            : [
                {
                  name: 'twitter:card',
                  content: 'summary',
                },
              ],
        )
        .concat(meta)}
    >
      <script>
        {`
          function getMobileOperatingSystem() {
            var userAgent = navigator.userAgent || navigator.vendor || window.opera;
            if (/android/i.test(userAgent)) {
              return 'Android';
            }
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
              return 'iOS';
            }
            return 'unknown';
          }

          function DetectAndServe() {
            let os = getMobileOperatingSystem();
            if (os == 'Android') {
              window.location.href = 'https://play.google.com/store/apps/details?id=com.bestfriendfamoso.people';
            } else if (os == 'iOS') {
              window.location.href = 'https://apps.apple.com/us/app/best-friend-famoso/id1605031926';
            } else {
              window.location.href = 'https://apps.apple.com/us/app/best-friend-famoso/id1605031926';
            }
          }

          function checkUri(){
            var qs = decodeURIComponent(document.location.search);
            if (qs) {
              var uri = qs.split('?linkingUri=')[1];
              if (uri) {
                document.querySelectorAll('a')[0].setAttribute('href', uri);
                window.location.href = uri;
                // setTimeout(DetectAndServe, 2000);
              }
            }
          }

          if( document.readyState !== 'loading' ) {
            checkUri();
          } else {
            document.addEventListener('DOMContentLoaded', function () {
              checkUri();
            });
          }
        `}
      </script>
      <meta name="apple-itunes-app" content="app-id=1605031926" />
      <meta
        name="google-play-app"
        content="app-id=com.bestfriendfamoso.people"
      />
      <meta name="facebook-domain-verification" content="g52cc7615bnmc0qo7zsoxqnx51pxts" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="apple-touch-icon" href={AppLogo} />
      <link rel="android-touch-icon" href={AppLogo} />
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  data: {
    author: null,
    ogImage: null,
    seo: { focus_synonyms: [], meta_description: null, seo_title: null },
  },
};

SEO.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.shape({}).isRequired,
};

export default SEO;
